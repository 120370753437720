import { get, post } from './request';
import md5 from 'js-md5';
// 测试秘钥key值：m69uymmhyw
// 生产秘钥key值：gw3ap7gxw8
let Authorization = md5("gw3ap7gxw8")

// 新闻-类别列表
function getNewsTypes(data){
    return post('/app/getNewsTypes', data)
}

// 新闻-新闻列表
function getNews(data){
    return post('/app/getNews', data)
}

// 新闻-页面详情
function getNewsInfo(data){
    return get('/app/getNewsInfo', data)
}

// 新闻-相关新闻
function getRelatedNewsList(data){
    return get('/app/getRelatedNewsList', data)
}

// 新闻-更新浏览量
function updateNum(data){
    return get('/app/updateNum', data)
}

// 工程设备-类别列表
function getEquipmentTypes(data){
    return post('/app/getEquipmentTypes', data)
}

// 工程设备-列表
function getEquipment(data){
    return post('/app/getEquipment', data)
}

// 企业服务-筑猎服务-立即咨询-岗位级别
function getPositionLevel(data){
    return post('/app/getPositionLevel', data)
}

// 企业服务-筑猎服务-立即咨询-专业类别
function getProfessionalCategory(data){
    return post('/app/getProfessionalCategory', data)
}

// 企业服务-筑猎服务
function addBuildingSub(data){
    return post('/app/addBuildingSub', data)
}

// 通用获取接口
function getInfo(data){
    return post('/news/getNews', data)
}

// 新闻-页面详情
function getNewsInfoPc(data){
    return get('/news/getNewsInfo', data)
}

// 金融服务-页面详情
function getFinancialInfo(data){
    return get('/app/getFinancialInfo', data)
}


// 金融服务-工程保险-意向提交
function addInsuranceSub(data){
    return post('/intention/addInsuranceSub', data)
}

// 数字服务-政务数字化-数字城建-意向 
function addDigitalSub(data){
    return post('/intention/addDigitalSub', data)
}

// 通用-业务咨询
function addLeaseSub(data){
    return post('/intention/addLeaseSub', data, Authorization)
}

// 新闻搜索-热门搜索词
function getPopularKeys(data){
    return post('/app/getPopularKeys', data)
}

// 数字化服务主页
function getPolymerizationList(data){
    return post('/news/getPolymerizationList', data)
}

// 政务数字化服务主页
function getBuilding(data){
    return post('/news/getBuildingGovernmentList', data)
}
// 金融服务-列表
function getFinancialList(data){
    return post('/app/getFinancialList', data)
}

// 专业知识-第一层选项
function getVideoCTypeList(data){
    return get('/news/getVideoCTypeList', data)
}

// 专业知识-第二层选项
function getVideoTypeList(data){
    return get('/news/getVideoTypeList', data)
}

// 专业知识-第三层选项
function getVideoTwoTypeList(data){
    return get('/news/getVideoTwoTypeList', data)
}

// 专业知识-视频列表
function getVideoList(data){
    return post('/news/getVideoList', data)
}

// 城市ip定位
function getCityByIp(data){
    return post('/news/getCityByIp', data)
}





export {
    getCityByIp,
    getNewsTypes,
    getNews,
    getNewsInfo,
    getNewsInfoPc,
    getRelatedNewsList,
    updateNum,
    getEquipmentTypes,
    getEquipment,
    getPositionLevel,
    getProfessionalCategory,
    addBuildingSub,
    getInfo,
    addInsuranceSub,
    addDigitalSub,
    getPopularKeys,
    addLeaseSub,
    getPolymerizationList,
    getBuilding,
    getFinancialList,
    getVideoCTypeList,
    getVideoTypeList,
    getVideoTwoTypeList,
    getVideoList,
    getFinancialInfo
}