<template>
  <div class="appMain" :style="{ paddingTop: $store.state.safetyTop + 'px' }">
    <router-view v-slot="{ Component }">
      <keep-alive v-if="!$route.meta.keepAlive">
        <component :is="Component"></component>
      </keep-alive>
      <component v-else :is="Component" :key="$route.fullPath"></component>
    </router-view>
    <div :class="['cPageBtns', { show: isScrolled, up: close }]">
      <div class="wr">
        <!-- <div class="ai" @click="close = false"><div class="i"></div></div> -->
        <div class="hid" >
          <!-- <div class="a robot" @click="skipOutsideChain('http://114.242.210.44:5200/#/aichat/app')">
            <div class="icon"></div>
            <div class="p">筑小<br/>智</div>
          </div> -->
          <div class="a robot" @click="skipOutsideChain('https://www.huajiantong.com/zhuxiaozhi/')">
            <div class="icon"></div>
            <div class="p">筑小<br/>智</div>
          </div>
          <!-- @click="skipOutsideChain('https://aicc.caih.com/chat_online/index?channelId=3398bc6cd46245f6963410a9c6a09155')" -->
          <div class="a wx" id="qiYuBtn" @click="skipOutsideChain('https://aicc.caih.com/chat_online/index?channelId=3398bc6cd46245f6963410a9c6a09155')" >
            <div class="icon"></div>
            <div class="p">在线<br />咨询</div>
          </div>
          <!-- <div class="close" @click="close = true"></div> -->
        </div>
        <div class="a top" @click="goTop"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getInfo } from "@/api/http";
import { skipOutsideChain } from '@/api/public';
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
const isScrolled = ref(false);
const close = ref(false);

// 移动设备的安全区
function callBackSafeArea(top, right, bottom, left, density) {
  let num = top || 0;
  let ratio = density;
  let resNum = Math.ceil(num / ratio);
  store.commit("setSafetyArea", resNum);
}
const getMobileInfo = () => {
  let top = store.state.safetyTop;
  if (top === null) {
    window.location.href = "bridge:/base/getSafeArea/pageInit";
  }
};
// 回到顶部
const goTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};



// 网页客服代码 - 七鱼
function qiYuKax() {
  (function (w, d, n, a, j) {
    w[n] =
      w[n] ||
      function () {
        (w[n].a = w[n].a || []).push(arguments);
      };
    j = d.createElement("script");
    j.async = true;
    j.src =
      "https://qiyukf.com/script/9fcb97e3e6c4428404eec71c8ceff8b7.js?hidden=1";
    d.body.appendChild(j);
  })(window, document, "ysf");

  // console.log(window.location.href)
  ysf("config", {
    // uid: "YY100", // 用户唯一标识UId,没有可以不传，那就是匿名用户，包括data中的信息
    robotShuntSwitch: 1, // 机器人优先开关
    //level : 1, // vip级别
    // title: "订单咨询入口", // 来源页面名称
    referrer: "https://www.huajiantongyidong.com/", // 来源页面地址，可以传入口链接
    groupid: "485533990", //分配的客服组，不同入口传不同业务的客服组
    qtype: 4496049, //对应渠道的机器人热门问题模板
    success: function () {
      // ysf("open");
      let qyBtn = document.getElementById("qiYuBtn")
      qyBtn.onclick= function (){
        ysf("open", {
          templateId: 6687097, //访客模板，控制风格的模板，不同的入口指定不同的模板
        });
      }
    
    },
    error: function () {
      // handle error
    },
  });
}

// ios审核隐藏金融支付
async function getIosState() {
  const { data: data } = await getInfo({
    coId: 649,
  });
  if (data.data[0].isTop) {
    const userAgent = navigator.userAgent;
    let isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    store.commit("setIosAuditState", isIOS);
  }
}
getIosState();

onMounted(function () {
  // qiYuKax()
  window.addEventListener("scroll", function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    isScrolled.value = scrollTop > 300;
  });

  // 获取手机设备安全区域
  window.callBackSafeArea = callBackSafeArea;
  getMobileInfo();
});
</script>
<style lang="scss">
.cPageBtns {
  position: fixed;
  right: 0.2rem;
  top: 50%;
  margin-top: -2rem;
  width: 0.7rem;
  z-index: 50;
  transition: all 0.4s 0.6s;
  .top {
    transition: all 0.4s;
    opacity: 0;
    pointer-events: none;
  }
  &.show {
    .top {
      opacity: 1;
      pointer-events: all;
    }
  }
  .a {
    position: relative;
    padding-top: 0.7rem;
    border-radius: 1.5rem;
    margin-bottom: 0.19rem;
    height: 1.46rem;
    background: url(~@/assets/images/iconPopBtnBg.png) no-repeat center;
    background-size: 100% 100%;
    box-shadow: 0 0.1rem 0.16rem rgba(0, 0, 0, 0.03);
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      position: absolute;
      top: 0.04rem;
      left: 50%;
      transform: translateX(-50%);
      background: no-repeat top center;
      background-size: contain;
    }
    .p {
      position: relative;
      z-index: 5;
      font-size: 0.22rem;
      color: #fff;
      text-align: center;
      line-height: 0.3rem;
    }
  }
  .robot {
    .icon {
      width: 0.8rem;
      height: 0.8rem;
      background-image: url(~@/assets/images/iconRobot.png);
      background-size: 74% auto;
    }
  }
  .wx {
    .icon {
      top: 0.19rem;
      width: 0.42rem;
      height: 0.42rem;
      background-image: url(~@/assets/images/iconWx.png);
    }
  }
  .top {
    height: 0.7rem;
    background-image: url(~@/assets/images/iconGoTop.png);
  }
  .ai {
    position: absolute;
    top: 0.04rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
    margin-right: 0;
    opacity: 1;
    transition: all 0.4s;
    pointer-events: none;
    .i {
      width: 0.8rem;
      height: 0.8rem;
      opacity: 0;
      transition: all 0.4s 0.6s;
      background-image: url(~@/assets/images/iconRobot.png);
      background-size: 100% 100%;
    }
  }
  .hid {
    overflow: hidden;
    width: 0.8rem;
    height: 4.2rem;
    padding: 0 0.05rem;
    margin-left: -0.05rem;
    transition: all 0.4s 0.6s;
    & > * {
      transition: all 0.4s 0.6s;
    }
  }
}
.close {
  width: 0.4rem;
  height: 0.4rem;
  margin: 0.2rem auto 0;
  border-radius: 50%;
  background: url(~@/assets/images/iconPopClose.png) no-repeat center;
  background-size: contain;
}
.cPageBtns.up {
  margin-top: -1rem;
  transition-delay: 0s;
  .ai {
    opacity: 0.5;
    margin-left: 0.4rem;
    transition-delay: 0.6s;
    pointer-events: all;
    .i {
      opacity: 1;
      transition-delay: 0s;
    }
  }
  .hid {
    pointer-events: none;
    height: 1.1rem;
    transition-delay: 0s;
    & > * {
      opacity: 0;
      transition-delay: 0s;
    }
  }
}
</style>